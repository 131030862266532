import React from 'react';
import { navigate } from 'gatsby';
import SEO from '../components/seo';
import { Wrapper } from '../components/layout';
import {
  Typography as T,
  Card,
  Box,
  Flex,
  Button,
  Separator,
} from '@fuji-ui/core';
import styled from 'styled-components';

const PriceCard = styled(Card)`
  ul {
    padding-left: 1rem;
  }
  li {
    line-height: 1.5;
  }
`;
const PriceTag = (props) => <T.H3 fontSize="xl" fontWeight={400} {...props} />;
const PriceRemarks = (props) => <T.P mt="xs" fontSize="s" {...props} />;

const CardTitle = styled(T.H2)`
  font-size: ${(props) => props.theme.fontSizes.s};
  opacity: 0.8;
  text-transform: uppercase;
  color: ${(props) => props.theme.colors.primary};
`;

// const CardTitle = styled(T.H2)({
//   fontSize: 's',
//   opacity: 0.8,
//   textTransform: 'uppercase',
//   color: 'primary',
// });

// };
// const CardTitle = (props) => (
//   <T.H2
//     fontSize="s"
//     opacity={0.8}
//     textTransform="uppercase"
//     color="primary"
//     {...props}
//   />
// );
const SectionTitle = (props) => <T.Subtitle1 fontSize="s" {...props} />;

const Pricing = (props) => {
  return (
    <>
      <SEO lang="en" title="Pricing" />
      <Wrapper>
        <Box
          as="section"
          m="auto"
          mt="l"
          textAlign="center"
          maxWidth={[null, 720, 720, null]}
        >
          <T.H1>
            A simple pricing <br />
            with no hidden cost.
          </T.H1>
          <T.P>
            We understand how hard it is to start a new business, thus we're
            offering flexible plans and grow with the business.
          </T.P>
        </Box>
        <Flex
          flexDirection={['column', null, 'row', null]}
          justifyContent="center"
          mt="l"
        >
          <PriceCard m="m">
            <CardTitle>Basic</CardTitle>
            <PriceTag>
              <strong>FREE</strong>
            </PriceTag>
            <PriceRemarks>2% + HK$12 per transaction</PriceRemarks>
            <Separator />
            <SectionTitle mt={0}>Features</SectionTitle>
            <ul>
              <li>Enrolment form builder</li>
              <li>Course scheduler</li>
              <li>Support credit/debit cards payment.</li>
            </ul>
            <Button disabled>Coming soon</Button>
          </PriceCard>
          <PriceCard m="m">
            <CardTitle>Plus</CardTitle>
            <PriceTag>
              <strong>HK$880</strong>/month
            </PriceTag>
            <PriceRemarks>
              2% + HK$12 per credit/debit card transaction
            </PriceRemarks>
            <Separator />
            <SectionTitle mt={0}>Features</SectionTitle>
            <ul>
              <li>
                <b>Includes everything in basic plan, plus</b>
              </li>
              <li>Alipay / FPS integration</li>
              <li>Private cloud hosting (hosting cost excluded)</li>
            </ul>
            <Button onClick={() => navigate('/request')}>Contact us</Button>
            <T.P fontSize="xs">
              <i>
                Schools and non-profit entities can enjoy up to 50% discount on
                the subscription fee.
              </i>
            </T.P>
          </PriceCard>
        </Flex>
      </Wrapper>
    </>
  );
};

export default Pricing;
